import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import profile from "../content/images/profile.jpg"
import contact from "../content/images/contact.png"

const AboutPage = () => {
  return(
  <Layout>
    <SEO title="About me" />
      <img
          src={profile}
          className = "profile"
          alt = "profile">
      </img>
      <div style={{ paddingTop: "3rem"}}>
      <p>Hi! My name is Raluca and I'm a cartographer. My favorite medium for creating maps is the web. This is why I taught
        myself web development and these days I call myself a web cartographer. In my daily job I work with an awesome
        team on the 3D side of the <a href="https://developers.arcgis.com/javascript/" target="_blank" rel="noreferrer">ArcGIS API for JavaScript</a>:
        issues related to documentation, testing, demos and blog posts land mostly on my desk. In the rest of my time I like
        to experiment with data visualizations and write about it.
      </p>
      <p>Previously I worked at the <a href="https://ikg.ethz.ch/en/" target="_blank" rel="noreferrer">Institute of Cartography and Geoinformation, ETH Zurich</a>,
        making 3D maps for the Atlas of Switzerland. During that time I also worked as teaching assistant in web cartography for Bachelor students.
      </p>
      <p>
        Even more previous to that I had a dream job making maps in the
        Swiss Alps for the <a href="https://www.nationalpark.ch/en/" target="_blank" rel="noreferrer">Swiss National Park</a>.
        When I wasn't in front of a computer, I was mostly hiking in the mountains. As I said, a dream job!
        </p>
        <div style={{ textAlign: "center"}}>
        <img
          src={contact}
          style={{
            borderRadius: "50%",
            maxWidth: 300,
            padding: "3px"
          }}
          alt = "profile">
          </img>
          </div>
        <p>If you want to get in touch, write to me on <a
          href="https://www.twitter.com/nicolaraluk">Twitter</a> or <a
          href="mailto:mailatraluca-nicola.net">email me</a>.
        </p>
    </div>

  </Layout>)
}

export default AboutPage
